import { createContext, useState, useEffect, ReactNode } from "react";
import { Dispatch, SetStateAction } from "react";
import { User } from "../../interfaces/models/User";

interface AuthProviderProps {
  children: ReactNode;
}

interface IAuth {
  id: string;
  role: string;
  accessToken: string;
  getAuth: boolean;
  user: User | null;
}

interface IAuthContext {
  auth: IAuth;
  setAuth: Dispatch<SetStateAction<IAuth>>;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<IAuth>({
    id: "",
    role: "",
    accessToken: "",
    getAuth: false,
    user: null,
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
