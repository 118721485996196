import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        'Content-Type': 'application/json'
    }
});

api.interceptors.request.use(
    (config) => {
      config.withCredentials = true; 
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default api;