import "./App.css";
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CreateOperation } from "./pages/Operations/CreateOperation";
import { Operations } from "./pages/Operations/Operations";
import { OperationDetail } from "./pages/Operations/OperationDetail";
import { OperationsHistory } from "./pages/Operations/OperationsHistory";
import { AuthProvider } from "./components/context/AuthProvider";
import RequireAuth from "./components/login/RequireAuth";
import PersistLogin from "./components/login/PersistLogin";
import { Settings } from "./pages/Settings";
import { Customers } from "./pages/Customers/Customers";
import { CustomerDetail } from "./pages/Customers/CustomerDetail";
import { CreateCustomer } from "./pages/Customers/CreateCustomer";
import { Users } from "./pages/Users/Users";
import { UserDetail } from "./pages/Users/UserDetail";
import { CreateUser } from "./pages/Users/CreateUser";
import { Profile } from "./pages/Users/Profile";
import { Base } from "./components/shared/Base";
import { NewPassForm } from "./pages/NewPass";
import { ResetPass } from "./pages/ResetPass";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/new-pass-request" element={<NewPassForm />} />
          <Route path="/reset-password" element={<ResetPass />} />
          <Route element={<PersistLogin />}>
            <Route
              element={
                <RequireAuth allowedRoles={["ADMIN", "PROVIDER", "CUSTOMER"]} />
              }
            >
              <Route element={<Base />}>
                <Route path="/operations" element={<Operations />} />
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/operations-history"
                  element={<OperationsHistory />}
                />
                <Route
                  path="/operation-detail/:id/:redirect"
                  element={<OperationDetail />}
                />
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Home />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={["ADMIN"]} />}>
              <Route element={<Base />}>
                <Route path="/create-user" element={<CreateUser />} />
              </Route>
            </Route>
            <Route
              element={<RequireAuth allowedRoles={["ADMIN", "PROVIDER"]} />}
            >
              <Route element={<Base />}>
                <Route path="/customers" element={<Customers />} />
                <Route
                  path="/customer-detail/:id"
                  element={<CustomerDetail />}
                />
                <Route path="/create-customer" element={<CreateCustomer />} />
                <Route path="/create-operation" element={<CreateOperation />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/users" element={<Users />} />
                <Route path="/user-detail/:id" element={<UserDetail />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
