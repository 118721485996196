import React, { ReactNode, useState } from "react";
import { Avatar, Button, Drawer, Input, Layout, Menu } from "antd";
import {
  SearchOutlined,
  UserOutlined,
  MenuOutlined,
  LogoutOutlined,
  SettingOutlined,
  HistoryOutlined,
  FileTextOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Content, Header } from "antd/es/layout/layout";
import { LayoutDashboard } from "lucide-react";
import { moduleName } from "../../services/atoms";
import { useAtomValue } from "jotai";
import RoleBased from "./RoleBased";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import UserFilter from "../operations/UserFilter";

interface Props {
  children?: ReactNode;
}

export const Base = ({ children }: Props) => {
  const module = useAtomValue(moduleName);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const { auth } = useAuth();

  const navigate = useNavigate();
  const logout = useLogout();

  const singOut = async () => {
    await logout(auth.accessToken);
    navigate("/login");
  };
  return (
    <Layout className="min-h-screen bg-[#F5F7FA]">
      <Header className="bg-[#2D3B47] px-4 flex items-center justify-between h-14 shadow-lg fixed w-full top-0 z-50">
        <div className="flex items-center gap-3">
          <Button
            type="text"
            icon={<MenuOutlined className="text-[#9ed4d9] text-xl" />}
            onClick={() => setDrawerOpen(true)}
            className="flex items-center justify-center !p-2"
          />
          <div className="flex items-center gap-2">
            <img
              src="/logo-evidence-app.png"
              alt="Logo"
              style={{ width: 20 }}
            />
            <h1 className="text-white text-lg font-semibold m-0">{module}</h1>
          </div>
        </div>
        <RoleBased allowedRoles={["ADMIN"]}>
          {module == "Operaciones" || module == "Historial de Operaciones" ? (
            <div className="flex items-center gap-3">
              <Button
                type="text"
                icon={<SearchOutlined className="text-[#9ed4d9] text-xl" />}
                onClick={() => setSearchVisible(!searchVisible)}
                className="flex items-center justify-center !p-2"
              />
            </div>
          ) : (
            <></>
          )}
        </RoleBased>
      </Header>

      <div
        className={`fixed w-full bg-[#2D3B47] px-4 py-3 shadow-lg transition-all duration-300 z-40 ${
          searchVisible ? "top-14" : "-top-20"
        }`}
      >
        <RoleBased allowedRoles={["ADMIN"]}>
          <UserFilter />
        </RoleBased>
      </div>

      <Drawer
        title={
          <div className="flex items-center gap-3 px-2">
            <Avatar
              icon={<UserOutlined />}
              className="bg-[#9ed4d9]"
              size="large"
            />
            <div>
              <p className="font-medium m-0">
                {auth.user?.firstName + " " + auth.user?.lastName}
              </p>
              <p className="text-sm text-gray-500 m-0">{auth.user?.email}</p>
            </div>
          </div>
        }
        placement="left"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        width={280}
      >
        <RoleBased allowedRoles={["ADMIN", "PROVIDER"]}>
          <Menu
            mode="inline"
            className="border-none"
            items={[
              {
                key: "home",
                icon: <HomeOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link onClick={() => setDrawerOpen(false)} to="/home">
                    Inicio
                  </Link>
                ),
              },
              {
                key: "operations",
                icon: <FileTextOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link onClick={() => setDrawerOpen(false)} to="/operations">
                    Operaciones
                  </Link>
                ),
              },
              {
                key: "operations-history",
                icon: <HistoryOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link
                    onClick={() => setDrawerOpen(false)}
                    to="/operations-history"
                  >
                    Historial de Operaciones
                  </Link>
                ),
              },
              {
                key: "profile",
                icon: <UserOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link onClick={() => setDrawerOpen(false)} to="/profile">
                    Mi Perfil
                  </Link>
                ),
              },
              {
                key: "settings",
                icon: <SettingOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link onClick={() => setDrawerOpen(false)} to="/settings">
                    Configuración
                  </Link>
                ),
              },
              {
                key: "logout",
                icon: <LogoutOutlined style={{ fontSize: 18, color: "red" }} />,
                label: (
                  <span className="mt-4 text-red-500">
                    <Link onClick={singOut} to="/">
                      Cerrar Sesión
                    </Link>
                  </span>
                ),
              },
            ]}
          />
        </RoleBased>

        <RoleBased allowedRoles={["CUSTOMER"]}>
          <Menu
            mode="inline"
            className="border-none"
            items={[
              {
                key: "home",
                icon: <HomeOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link onClick={() => setDrawerOpen(false)} to="/home">
                    Inicio
                  </Link>
                ),
              },
              {
                key: "operations",
                icon: <FileTextOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link onClick={() => setDrawerOpen(false)} to="/operations">
                    Operaciones
                  </Link>
                ),
              },
              {
                key: "operations-history",
                icon: <HistoryOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link
                    onClick={() => setDrawerOpen(false)}
                    to="/operations-history"
                  >
                    Historial de Operaciones
                  </Link>
                ),
              },
              {
                key: "profile",
                icon: <UserOutlined style={{ fontSize: 18 }} />,
                label: (
                  <Link onClick={() => setDrawerOpen(false)} to="/profile">
                    Mi Perfil
                  </Link>
                ),
              },
              {
                key: "logout",
                icon: <LogoutOutlined style={{ fontSize: 18, color: "red" }} />,
                label: (
                  <span className="mt-4 text-red-500">
                    <Link onClick={singOut} to="/">
                      Cerrar Sesión
                    </Link>
                  </span>
                ),
              },
            ]}
          />
        </RoleBased>
      </Drawer>

      <Content className="mt-14">
        <div className="space-y-3">
          {children}
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
};
