import React, { useEffect, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { customersList, setModuleName } from "../../services/atoms";
import { Loading } from "../../components/shared/Loading";
import CustomersList from "../../components/customers/CustomersList";
import RoleBased from "../../components/shared/RoleBased";
import { notCustomer } from "../../validationSchemas/validateModules";
import { Plus } from "lucide-react";
import { SearchBar } from "../../components/shared/SearchBar";

export const Customers = () => {
  const customers = useAtomValue(customersList);
  const setModule = useSetAtom(setModuleName);
  const [filteredCustomers, setFilteredCustomers] = useState<any[]>([]);

  useEffect(() => {
    if (customers.state === "hasData") {
      setFilteredCustomers(customers.data.data);
    }
  }, [customers]);

  useEffect(() => {
    setModule("Clientes");
  }, [setModule]);

  if (customers.state === "loading") {
    return <Loading />;
  }

  if (customers.state === "hasData") {
    return (
      <div>
        <div
          className="p-4"
          style={{
            backgroundColor: "#f5f5f5",
            minHeight: "100vh",
          }}
        >
          <RoleBased allowedRoles={notCustomer}>
            <Link to="/create-customer">
              <Button
                shape="circle"
                type="primary"
                icon={<Plus size={35} />}
                style={{
                  width: 54,
                  height: 54,
                }}
                className="fixed right-4 bottom-4 bg-[#fdd34e] hover:bg-[#fdb159] border-none flex items-center justify-center shadow-lg hover:scale-105 transition-transform z-30"
              />
            </Link>
          </RoleBased>

            <SearchBar
              searchKeys={["companyName",  "id",]}
              data={customers.data.data}
              onSearchResults={(results) => setFilteredCustomers(results)}
              placeholder="Buscar por nombre de empresa o referencia..."
            />
          
          {filteredCustomers.length > 0 ? (
            <CustomersList customers={filteredCustomers} />
          ) : (
            <div style={{ textAlign: "center" }}>
              No hay clientes disponibles
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};
