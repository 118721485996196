import { CreateUserValues, User } from "../interfaces/models/User";
import api from "./ApiService";
import { handleApiError } from "./handleApiError";

export const getUser = async (id: string, token: string) => {
  try {
    const response = await api.get(`/Users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getAllUsers = async (token: string) => {
  try {
    const response = await api.get(`/Users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateUserById = async (token: string, operation: User) => {
  try {
    const response = await api.put(`/Users/${operation.id}`, operation, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const createUser = async (
  token: string,
  operation: CreateUserValues
) => {
  try {
    const response = await api.post("/Users", operation, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const createdUser = response.data;

    return createdUser;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const deleteUser = async (id: number, token: string) => {
  try {
    const response = await api.delete(`/Users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const createdUser = response.data;
    return createdUser;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const getUserById = async (id: any, token: string) => {
  try {
    const response = await api.get(`/Users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const passChange = async (values: any, token: string) => {
  try {
    const response = await api.post(`/Users/password-change`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
