import React, { useState } from 'react';
import { Button, Modal, Space } from 'antd';

const SelectImagesModal: React.FC<any> = ({ handleAddImages }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    document.getElementById("galleryInput")?.click();
  };

  const handleCancel = () => {
    document.getElementById("cameraInput")?.click();
    setOpen(false);
  };

  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setLoading(true);
      await handleAddImages(filesArray);
      setLoading(false);
      event.target.value = "";
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop:18}}>
      <Space>
        <Button type="primary" disabled={loading} onClick={showModal}>
          {loading ? "Adjuntando..." : "Adjuntar Imagenes"}
        </Button>
      </Space>

      <Modal
        title="Evidencias"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Tomar foto
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Adjuntar imágenes
          </Button>,
        ]}
        closeIcon={<span style={{ fontSize: "18px", color: "#000" }}>×</span>}
      >
        <p>
          ¿Deseas adjuntar imágenes desde la galería o tomar una foto?
        </p>
      </Modal>

      <input
        id="galleryInput"
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileSelection}
        style={{ display: "none" }}
      />

      <input
        id="cameraInput"
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleFileSelection}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default SelectImagesModal;
