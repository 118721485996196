import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getOperationById,
  updateOperationById,
  addOperationEvidence,
  sendEvidenceEmail,
} from "../../services/OperationsService";
import { Operation } from "../../interfaces/models/Operation";
import useAuth from "../../hooks/useAuth";
import MultiSelectImages from "../../components/operations/MultiSelectImages";
import SelectImagesModal from "../../components/operations/SelectImagesModal";
import { OpenNotification } from "../../components/shared/Notification";
import { Loading } from "../../components/shared/Loading";
import { useSetAtom } from "jotai";
import { getOperations, getHistoryOperations, setModuleName } from "../../services/atoms";
import UpdateOperationForm from "../../components/forms/UpdateOperationForm";
import { OperationDetailCustomer } from "./OperationDetailCustomer";

export const OperationDetail = () => {
  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState<Operation | null>(null);
  const { id, redirect } = useParams();
  const { auth } = useAuth();
  const refresh = useSetAtom(getOperations);
  const refreshHistory = useSetAtom(getHistoryOperations);
  const setModule = useSetAtom(setModuleName);

  useEffect(() => {
    if (id) {
      getOperation();
      setModule("Operaciones")
    }
  }, [id, auth?.accessToken]);

  const getOperation = async () => {
    try {
      setLoading(true);
      const data = await getOperationById(
        parseInt(id ?? "0"),
        auth?.accessToken
      );
      setOperation(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleAddImages = async (files: File[]) => {
    const formData = new FormData();
    formData.append("OperationId", id ?? "");
    files.forEach((file, index) => {
      formData.append(`EvidenceFiles[${index}].File`, file);
      formData.append(`EvidenceFiles[${index}].FileName`, file.name);
    });
    const response = await addOperationEvidence(
      parseInt(id ?? ""),
      formData,
      auth?.accessToken
    );
    await getOperation();
    OpenNotification("success", "topLeft", response?.message);
  };

  const handleDeleteEvidence = (deletedIds: number[]) => {
    setOperation((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        operationFiles: prev.operationFiles.filter(
          (file) => !deletedIds.includes(file.id)
        ),
      };
    });
  };

  const handleUpdate = async (values: any) => {
    try {
      setLoading(true);
      values.id = parseInt(id ?? "");
      const response = await updateOperationById(auth.accessToken, values);
      OpenNotification("success", "bottomRight", response.message);
      refresh();
      await getOperation();
      refreshHistory();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendEvidence = async () => {
    try {
      setLoading(true);
      const response = await sendEvidenceEmail(
        parseInt(id ?? "0"),
        auth.accessToken
      );
      await getOperation();
      OpenNotification("success", "bottomRight", response.message);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div
        style={{
          minHeight: "110vh",
        }}
        className="bg-white"
      >
        {operation ? (
          auth.role !== "CUSTOMER" ? (
            <>
              <UpdateOperationForm
                operation={operation}
                handleUpdate={handleUpdate}
                redirect={redirect ?? "/"}
                handleSendEvidence={handleSendEvidence}
              />
              <SelectImagesModal handleAddImages={handleAddImages} />
              <MultiSelectImages
                images={operation.operationFiles}
                onDeleteComplete={(deletedIds: number[]) =>
                  handleDeleteEvidence(deletedIds)
                }
              />
            </>
          ) : (
            <>
              <OperationDetailCustomer operation={operation} />
              <MultiSelectImages
                images={operation.operationFiles}
                onDeleteComplete={() => {}}
              />
            </>
          )
        ) : (
          <div className="text-center">
            <p>No se encontró la operación</p>
          </div>
        )}
      </div>
    </>
  );
};
