import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getCustomerById,
  updateCustomerById,
} from "../../services/CustomersService";
import { Customer } from "../../interfaces/models/Customer";
import { FaArrowLeft } from "react-icons/fa";
import { Row, Col } from "antd";
import useAuth from "../../hooks/useAuth";
import { OpenNotification } from "../../components/shared/Notification";
import { Loading } from "../../components/shared/Loading";
import { useSetAtom } from "jotai";
import { getCustomers, setModuleName } from "../../services/atoms";
import { CustomerForm } from "../../components/forms/CustomerForm";

export const CustomerDetail = () => {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const { id } = useParams();
  const { auth } = useAuth();
  const refresh = useSetAtom(getCustomers);
  const setModule = useSetAtom(setModuleName);

  useEffect(()=>{
    setModule("Clientes")
  },[setModule])

  useEffect(() => {
    if (id) {
      fetchCustomerData();
    }
  }, [id]);

  const fetchCustomerData = async () => {
    setLoading(true);
    try {
      const response = await getCustomerById(
        parseInt(id ?? "0"),
        auth?.accessToken
      );
      setCustomer(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      setLoading(true);
      values.id = parseInt(id ?? "0");
      const response = await updateCustomerById(auth.accessToken, values);
      OpenNotification("success", "bottomRight", response.message);
      refresh();
      fetchCustomerData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div className="h-screen bg-white p-5">
        <div className=" flex items-center">
          <Link to="/customers" className="text-black">
            <FaArrowLeft fontSize={20} />
          </Link>
          <div style={{ margin: 6 }} className="font-medium">
            Detalles del Cliente
          </div>
        </div>
        {customer ? (
          <CustomerForm handleRequest={handleUpdate} data={customer} />
        ) : (
          <p className="text-center">Cargando datos del cliente...</p>
        )}
      </div>
    </div>
  );
};
