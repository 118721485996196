import { jwtDecode } from "jwt-decode";
import { refreshToken } from "../services/AuthService";
import useAuth from "./useAuth";
import { getUser } from "../services/UsersService";
import { tokenAtom } from "../services/atoms";
import { useAtom, useSetAtom } from "jotai";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const setToken = useSetAtom(tokenAtom);

  const refresh = async () => {
    const response = await refreshToken();
    const decoded = jwtDecode(response.data) as { id: string; role: string };
    const userData = await getUser(decoded.id, response.data);
    setAuth({
      accessToken: response.data,
      id: decoded.id,
      role: decoded.role,
      getAuth: true,
      user: userData.data,
    });
    setToken(response.data);
  };
  return refresh;
};

export default useRefreshToken;
