import { Link } from "react-router-dom";
import { UserCircle, Users } from "lucide-react";
import { Card, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { setModuleName } from "../services/atoms";
import { useEffect } from "react";
import { useSetAtom } from "jotai";
import RoleBased from "../components/shared/RoleBased";

export const Settings = () => {
  const setModule = useSetAtom(setModuleName);

  useEffect(() => {
    setModule("Configuración");
  }, [setModule]);
  return (
    <Layout className="min-h-screen bg-[#F5F7FA]">
      <Content className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <Link
            to="/customers"
            className="bg-white p-5 flex rounded-md mt-4 justify-center"
          >
            <Card
              key="customers"
              hoverable
              className="bg-white hover:shadow-lg transition-all duration-300 border-none"
            >
              <div className="flex flex-col items-center text-center gap-4 py-4">
                <div className="bg-[#112752] p-4 rounded-full">
                  <Users size={32} className="text-[#9ed4d9]" />
                </div>
                <div>
                  <h3 className="text-[#112752] text-xl font-semibold mb-2">
                    Clientes
                  </h3>
                  <p className="text-gray-600">Ver clientes existentes</p>
                </div>
              </div>
            </Card>
          </Link>
          <RoleBased allowedRoles={["ADMIN"]}>
            <Link
              to="/users"
              className="bg-white p-5 flex rounded-md mt-4 justify-center"
            >
              <Card
                key="users"
                hoverable
                className="bg-white hover:shadow-lg transition-all duration-300 border-none"
              >
                <div className="flex flex-col items-center text-center gap-4 py-4">
                  <div className="bg-[#112752] p-4 rounded-full">
                    <UserCircle size={32} className="text-[#9ed4d9]" />
                  </div>
                  <div>
                    <h3 className="text-[#112752] text-xl font-semibold mb-2">
                      Usuarios
                    </h3>
                    <p className="text-gray-600">Ver usuarios existentes</p>
                  </div>
                </div>
              </Card>
            </Link>
          </RoleBased>
        </div>
      </Content>
    </Layout>
  );
};
