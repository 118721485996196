import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { deleteCustomer } from "../../services/CustomersService";
import { notCustomer } from "../../validationSchemas/validateModules";
import RoleBased from "../shared/RoleBased";
import { getCustomers, tokenAtom } from "../../services/atoms";
import { useAtomValue, useSetAtom } from "jotai";
import { OpenNotification } from "../shared/Notification";
import { Customer } from "../../interfaces/models/Customer";
import { UserOutlined } from "@ant-design/icons";


interface CustomerCardListProps {
  customers: Customer[];
}

const CustomersList: React.FC<CustomerCardListProps> = ({ customers }) => {
  const navigate = useNavigate();
  const token = useAtomValue(tokenAtom);
  const refreshCustomers = useSetAtom(getCustomers);

  const handleCardClick = (id: any) => {
    navigate(`/customer-detail/${id}`);
  };

  const handleDelete = async (id: any, event: React.MouseEvent) => {
    try {
      event.stopPropagation();
      const opResponse = await deleteCustomer(id, token);
      refreshCustomers();
      OpenNotification("success", "bottomRight", opResponse.message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="space-y-3">
      {customers.map((data) => (
        <div
          key={data.id}
          className="bg-white rounded-xl shadow-sm p-4"
          onClick={() => handleCardClick(data.id)}
        >
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <span
                className="text-[#112752] font-medium"
                style={{
                  maxWidth: 210,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <UserOutlined style={{ fontSize: "17px", marginRight: "5px" }} />
                {data.companyName}
              </span>
            </div>
            <RoleBased allowedRoles={notCustomer}>
              <Button
                type="text"
                danger
                size="small"
                icon={
                  <span
                    className="text-lg"
                    onClick={(event) => handleDelete(data.id, event)}
                  >
                    x
                  </span>
                }
              />
            </RoleBased>
          </div>
          <p className="text-gray-600 text-sm mb-1">ID: {data.id}</p>
          <p className="text-gray-600 text-sm mb-1">
            Correo: <b>{data.email}</b>
          </p>
        </div>
      ))}
    </div>
  );
};

export default CustomersList;
