import React, { useState } from "react";
import { Layout, Input, Button, Form } from "antd";
import { UserCircle } from "lucide-react";
import { Loading } from "../components/shared/Loading";
import { passReset } from "../services/AuthService";
import { OpenNotification } from "../components/shared/Notification";
import { validatePassword } from "../validationSchemas/creation";
import { useLocation } from "react-router-dom";

export const ResetPass = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");


  const onPassRequest = async (values: any) => {
    values.token = token;
    values.email = email;
    try {
      const response = await passReset(values);
      OpenNotification("success", "bottomRight", response.message);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <Layout className="min-h-screen bg-[#F5F7FA]">
      <div className="w-full max-w-sm mx-auto px-4 flex flex-col items-center justify-center min-h-screen">
        <div className="m-5 flex items-center gap-3">
          <img
            style={{ width: 243 }}
            src="/logo-evidence-app-large.png"
            alt="Logo"
          />
        </div>

        <div className="w-full bg-white rounded-xl p-8 shadow-sm border border-gray-100">
          <div className="text-center mb-8">
            <UserCircle className="w-12 h-12 mx-auto mb-3 text-secondary" />
            <p className="text-sm text-gray-500 mt-1">
              Ingresa tu nueva contraseña para continuar
            </p>
          </div>

          <Form
            form={form}
            onFinish={onPassRequest}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              label="Contraseña"
              name="password"
              rules={[{ required: true, validator: validatePassword }]}
            >
              <Input.Password
                className="h-11 rounded-lg"
                type="password"
                placeholder="Contraseña"
              />
            </Form.Item>

            <Form.Item
              label="Confirmar Contraseña"
              name="passwordConfirm"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("La contraseñas no son iguales")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className="h-11 rounded-lg"
                placeholder="Confirmar Contraseña"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full h-11 bg-accent hover:bg-warning text-primary font-medium text-base rounded-lg shadow-sm"
              >
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Layout>
  );
};
