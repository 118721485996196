import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";

interface SearchBarProps<T> {
  searchKeys: string[];
  data: any[];
  onSearchResults: (results: any[]) => void;
  placeholder: string;
}

export const SearchBar = <T extends object>({
  searchKeys,
  data,
  onSearchResults,
  placeholder,
}: SearchBarProps<T>) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!searchTerm) {
      onSearchResults(data);
    } else {
      const fuse = new Fuse(data, {
        keys: searchKeys,
        threshold: 0.3,
      });
      const results = fuse.search(searchTerm).map((result) => result.item);
      onSearchResults(results);
    }
  }, [searchTerm, data, searchKeys, onSearchResults]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="flex justify-center mb-6">
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        className="w-80 h-12 px-4 rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 placeholder-gray-400 transition duration-200"
      />
    </div>
  );
};
