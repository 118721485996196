import React from "react";
import { Select } from "antd";
import { useAtomValue, useSetAtom } from "jotai";
import { updateUser, usersList } from "../../services/atoms";

export const UserFilter: React.FC = () => {
  const users = useAtomValue(usersList); 
  const setUser = useSetAtom(updateUser); 

  if (users.state !== "hasData") return null;

  return (
    <Select
      showSearch
      options={[
        { value: "all", label: "Todos los usuarios" },
        ...users.data.data.map(({ id, firstName, lastName }: any) => ({
          value: id,
          label: `${firstName} ${lastName}`,
        })),
      ]}
      placeholder="Filtrar por usuario"
      style={{ width: "100%" }}
      onChange={(value) => setUser(value)} 
    />
  );
};

export default UserFilter;
