import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserById, passChange } from "../../services/UsersService";
import { User } from "../../interfaces/models/User";
import { FaArrowLeft } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import { OpenNotification } from "../../components/shared/Notification";
import { Loading } from "../../components/shared/Loading";
import { ProfileForm } from "../../components/forms/ProfileForm";
import { useSetAtom } from "jotai";
import { setModuleName } from "../../services/atoms";

export const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { auth } = useAuth();
  const setModule = useSetAtom(setModuleName);

  useEffect(() => {
    if (auth.id) {
      getData();
      setModule("Mi Perfil");
    }
  }, [auth.id, auth?.accessToken]);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await getUserById(auth.id, auth?.accessToken);
      setUser(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      setLoading(true);
      values.id = auth.id;
      const response = await passChange(values, auth.accessToken);
      OpenNotification("success", "bottomRight", response.message);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="h-screen bg-white p-5">
        <div className="flex items-center">
          <Link to={"/"} className="text-black">
            <FaArrowLeft fontSize={20} />{" "}
          </Link>
            <div style={{ margin: 6 }} className="font-medium">
              Detalle del usuario
            </div>
        </div>

        {user ? (
          <ProfileForm handleRequest={handleUpdate} data={user} />
        ) : (
          <p className="text-center font-medium">
            Cargando datos del usuario...
          </p>
        )}
      </div>
    </>
  );
};
