import React, { useState } from "react";
import { Layout, Input, Button, Form, message } from "antd";
import { UserCircle, Lock } from "lucide-react";
import { Loading } from "../components/shared/Loading";
import { passResetRequest } from "../services/AuthService";
import { OpenNotification } from "../components/shared/Notification";

export const NewPassForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onPassRequest = async (values: any) => {
    console.log(values);
    setLoading(true);
    try {
      const response = await passResetRequest(values.email);
      OpenNotification("success", "bottomRight", response.message);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <Layout className="min-h-screen bg-[#F5F7FA]">
      <div className="w-full max-w-sm mx-auto px-4 flex flex-col items-center justify-center min-h-screen">
        <div className="m-5 flex items-center gap-3">
          <img
            style={{ width: 243 }}
            src="/logo-evidence-app-large.png"
            alt="Logo"
          />
        </div>

        <div className="w-full bg-white rounded-xl p-8 shadow-sm border border-gray-100">
          <div className="text-center mb-8">
            <UserCircle className="w-12 h-12 mx-auto mb-3 text-secondary" />
            <p className="text-sm text-gray-500 mt-1">
              Ingresa tu correo para continuar
            </p>
          </div>

          <Form
            form={form}
            onFinish={onPassRequest}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Por favor ingresa tu correo" },
              ]}
            >
              <Input
                prefix={<UserCircle className="text-secondary" size={18} />}
                placeholder="Correo"
                className="h-11 rounded-lg"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full h-11 bg-accent hover:bg-warning text-primary font-medium text-base rounded-lg shadow-sm"
              >
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Layout>
  );
};
