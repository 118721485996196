import api from "./ApiService";
import {
  CreateOperationValues,
  UpdateOperationValues,
  DeleteOperationEvidence,
} from "../interfaces/models/Operation";
import { handleApiError } from "./handleApiError";

export const getAllOperations = async (
  token: string,
  page: number,
  id: string
) => {
  try {
    const params = {
      page,
      pageSize: 10,
      sortColumn: "CreatedAt",
      sortOrder: "desc",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const url = id == "all" ? "/Operations" : `/Users/${id}/Operations`;

    const response = await api.get(url, { params, headers });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const getOperationsHistory = async (
  token: string,
  page: number,
  id: string
) => {
  try {
    const params = {
      page: page,
      pageSize: 10,
      searchColumns: "status",
      searchTerms: true,
      sortColumn: "CreatedAt",
      sortOrder: "desc",
    };

    const url = id == "all" ? "/Operations" : `/Users/${id}/Operations`;

    const response = await api.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const getOperationById = async (id: number, token: string) => {
  try {
    const response = await api.get(`/Operations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const updateOperationById = async (
  token: string,
  operation: UpdateOperationValues
) => {
  try {
    const response = await api.put(`/Operations/${operation.id}`, operation, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const createOperation = async (
  token: string,
  operation: CreateOperationValues
) => {
  try {
    const response = await api.post("/Operations", operation, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const createdOperation = response.data;

    return createdOperation;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const addOperationEvidence = async (
  id: number,
  files: FormData,
  token: string
) => {
  try {
    const response = await api.post(`/Operations/${id}/EvidenceFiles`, files, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    const createdOperation = response.data;
    return createdOperation;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const deleteEvidence = async (
  files: DeleteOperationEvidence,
  token: string
) => {
  try {
    const response = await api.delete(
      `/Operations/${files.OperationId}/EvidenceFiles`,
      {
        data: files,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const createdOperation = response.data;
    return createdOperation;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const deleteOperation = async (id: number, token: string) => {
  try {
    const response = await api.delete(`/Operations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const createdOperation = response.data;
    return createdOperation;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const sendEvidenceEmail = async (id: number, token: string) => {
  try {
    const response = await api.post(
      `/Operations/${id}/SendEvidenceEmail`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const createdOperation = response.data;
    return createdOperation;
  } catch (error: any) {
    handleApiError(error);
  }
};
