import { atom } from "jotai";
import { atomWithRefresh, loadable } from "jotai/utils";
import { getAllOperations, getOperationsHistory } from "./OperationsService";
import { getAllCustomers } from "./CustomersService";
import { getAllUsers } from "./UsersService";

export const tokenAtom = atom<string>("");

export const currentPage = atom(1);

export const getOperations = atomWithRefresh(async (get) => {
  return await getAllOperations(get(tokenAtom), get(currentPage), get(user));
});

export const getHistoryOperations = atomWithRefresh(async (get) => {
  return await getOperationsHistory(get(tokenAtom), get(currentPage),get(user));
});

export const getCustomers = atomWithRefresh(async (get) => {
  return await getAllCustomers(get(tokenAtom));
});

export const getUsers = atomWithRefresh(async (get) => {
  return await getAllUsers(get(tokenAtom));
});

const user = atom("all");

export const updateUser = atom(null, (get, set, update: string) => {
  set(user, update);
});

export const moduleName = atom ("");
export const setModuleName = atom(null, (get, set, update: string) => {
  set(moduleName, update);
});

export const operationsList = loadable(getOperations);
export const operationsHistoryList = loadable(getHistoryOperations);
export const customersList = loadable(getCustomers);
export const usersList = loadable(getUsers);
