import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';

type NotificationPlacement = NotificationArgsProps['placement'];

export const OpenNotification = (
  type: 'success' | 'error',
  placement: NotificationPlacement,
  message: string
) => {
  notification[type]({
    message: type === 'success' ? 'Éxito' : 'Error',
    description: message,
    placement,
  });
};
