import api from "./PrivateApiService";
import { handleApiError } from "./handleApiError";
export const login = async (email: string, password: string) => {
  try {
    const response = await api.post("/Login/login", { email, password });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const revoke = async (token: string) => {
  try {
    const response = await api.delete("/Login/revoke", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const refreshToken = async () => {
  try {
    const response = await api.post("/Login/refresh");
    return response.data;
  } catch (error: any) {
    //handleApiError(error);
    //console.log(error);
    throw error;
  }
};

export const facebook = async (accessToken:string) => {
  try {
    const response = await api.post("/Login/AuthFacebook", { accessToken });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const google = async (idToken:string) => {
  try {
    const response = await api.post("/Login/AuthGoogle", { idToken });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const passResetRequest = async (email:any) => {
  //console.log(email)
  try {
    const response = await api.post("/Login/new-password-request", { email });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};
export const passReset = async (values:any) => {
  try {
    const response = await api.post("/Login/password-reset", { ...values });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};
