import { Form, Input, Button } from "antd";

interface CustomerFormProps {
  handleRequest: (values: any) => void;
  data:any | null
}
export const CustomerForm = ({ handleRequest, data }: CustomerFormProps) => {
  const [form] = Form.useForm();
  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleRequest} initialValues={data} style={{marginTop:10}}>
        <Form.Item
          label="Nombre del Cliente"
          name="companyName"
          rules={[{ required: true, message: "El nombre es obligatorio" }]}
        >
          <Input placeholder="Nombre del cliente" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "El email es obligatorio" }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Teléfono"
          name="phone"
          rules={[{ required: true, message: "El teléfono es obligatorio" }]}
        >
          <Input placeholder="66266666" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
