import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserById, updateUserById } from "../../services/UsersService";
import { User } from "../../interfaces/models/User";
import { FaArrowLeft } from "react-icons/fa";
import { Row, Col } from "antd";
import useAuth from "../../hooks/useAuth";
import { OpenNotification } from "../../components/shared/Notification";
import { Loading } from "../../components/shared/Loading";
import { useSetAtom } from "jotai";
import { getUsers } from "../../services/atoms";
import { UserForm } from "../../components/forms/UserForm";

export const UserDetail = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { id } = useParams();
  const { auth } = useAuth();
  const refresh = useSetAtom(getUsers);

  useEffect(() => {
    if (id) {
      fetchUserData();
    }
  }, [id]);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await getUserById(id, auth?.accessToken);
      setUser(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      setLoading(true);
      const updatedUser = { ...values, id };
      const response = await updateUserById(auth.accessToken, updatedUser);
      OpenNotification("success", "bottomRight", response.message);
      refresh();
      fetchUserData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div className="h-screen bg-white p-5">
        <div className="flex items-center">
          <Link to="/users" className="text-black">
            <FaArrowLeft fontSize={20} />
          </Link>
          <div style={{ margin: 6 }} className="font-medium">
            Detalles del Usuario
          </div>
        </div>

        {user ? (
          <UserForm handleRequest={handleUpdate} data={user} type="update" />
        ) : (
          <p className="text-center">Cargando datos del usuario...</p>
        )}
      </div>
    </div>
  );
};
