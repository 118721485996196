import React, { useEffect, useState, useTransition } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Link } from "react-router-dom";
import { Button, Pagination } from "antd";
import {
  currentPage,
  operationsList,
  setModuleName,
} from "../../services/atoms";
import { Loading } from "../../components/shared/Loading";
import RoleBased from "../../components/shared/RoleBased";
import { notCustomer } from "../../validationSchemas/validateModules";
import { Plus } from "lucide-react";
import OperationsList from "../../components/operations/OperationsList";
import { useNavigate } from "react-router-dom";
import { SearchBar } from "../../components/shared/SearchBar";

export const Operations = () => {
  const operations = useAtomValue(operationsList);
  const [page, setPage] = useAtom(currentPage);
  const [isPending, startTransition] = useTransition();
  const [module, setModule] = useAtom(setModuleName);
  const navigate = useNavigate();
  const [filteredOperations, setFilteredOperations] = useState<any[]>([]);

  useEffect(() => {
    if (operations.state === "hasData") {
      setFilteredOperations(operations.data.data);
    }
  }, [operations]);

  useEffect(() => {
    setPage(1);
    setModule("Operaciones");
  }, [setPage, setModule]);

  const handlePageChange = async (page: number) => {
    startTransition(() => {
      setPage(page);
    });
  };

  const handleCardClick = (id: any) => {
    navigate(`/operation-detail/${id}/operations`);
  };

  if (operations.state === "loading") {
    return <Loading />;
  }

  if (isPending) {
    return <Loading />;
  }

  if (operations.state === "hasData") {
    return (
      <div>
        <RoleBased allowedRoles={notCustomer}>
          <Link to="/create-operation">
            <Button
              shape="circle"
              type="primary"
              icon={<Plus size={35} />}
              style={{
                width: 54,
                height: 54,
              }}
              className="fixed right-4 bottom-4 bg-[#fdd34e] hover:bg-[#fdb159] border-none flex items-center justify-center shadow-lg hover:scale-105 transition-transform z-30"
            />
          </Link>
        </RoleBased>

        <div
          className="p-4"
          style={{
            backgroundColor: "#f5f5f5",
            minHeight: "100vh",
          }}
        >
          <SearchBar
            searchKeys={["name", "description", "id", "customerName"]}
            data={operations.data.data}
            onSearchResults={(results) => setFilteredOperations(results)}
            placeholder="Buscar por nombre, referencia o cliente..."
          />
          
          {filteredOperations.length > 0 ? (
            <>
              <OperationsList
                operations={filteredOperations}
                handleCardClick={handleCardClick}
              />
              <div style={{ marginTop: 10 }}>
                <Pagination
                  current={operations.data.page}
                  pageSize={operations.data.pageSize}
                  total={operations.data.totalCount}
                  onChange={handlePageChange}
                  style={{ textAlign: "right" }}
                />
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center", fontSize: 14 }}>
              No hay operaciones disponibles
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
};
