import api from "./ApiService";
import { CreateCustomerValues, Customer } from "../interfaces/models/Customer";
import { handleApiError } from "./handleApiError";

export const getAllCustomers = async (token: string) => {
  try {
    const response = await api.get("/Customers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getCustomerById = async (id: number, token: string) => {
  try {
    const response = await api.get(`/Customers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateCustomerById = async (
  token: string,
  operation: Customer
) => {
  try {
    const response = await api.put(`/Customers/${operation.id}`, operation, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const createCustomer = async (
  token: string,
  operation: CreateCustomerValues
) => {
  try {
    const response = await api.post("/Customers", operation, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const createdCustomer = response.data;

    return createdCustomer;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const deleteCustomer = async (id: number, token: string) => {
  try {
    const response = await api.delete(`/Customers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const createdCustomer = response.data;
    return createdCustomer;
  } catch (error: any) {
    handleApiError(error);
  }
};
