import { revoke } from "../services/AuthService";
import useAuth from "./useAuth";

const useLogout = () =>{
    const {setAuth} = useAuth();
    const logout= async (accessToken:any) =>{
        await revoke(accessToken);
        setAuth({
            id: "",
            role: "",
            accessToken: "",
            getAuth: false,
            user:null
          });
    }
    return logout
}

export default useLogout;