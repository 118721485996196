import React from "react";

import { Operation } from "../../interfaces/models/Operation";

interface Props {
  operation: Operation;
}
export const OperationDetailCustomer: React.FC<Props> = ({ operation }) => {
  return (
    <div className="grid grid-cols-2 px-4 pt-2 mb-10">
      <DetailRow label="Cliente">
        <div>{operation.customerName} </div>
      </DetailRow>

      <DetailRow label="Referencia">{operation.id}</DetailRow>

      <DetailRow label="Fecha de Actualización">
        <div>
          {new Date(operation.updatedAt).toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </div>
      </DetailRow>

      <DetailRow label="Usuario Responsable">
        <div>{operation.userName} </div>
      </DetailRow>

      <DetailRow label="Nombre">
        <div>{operation.name}</div>
      </DetailRow>

      <DetailRow label="Descripción">
        <div>{operation.description} </div>
      </DetailRow>

      <DetailRow label="Estado">
        {operation.status ? "Finalizada" : "Pendiente"}
      </DetailRow>
    </div>
  );
};

const DetailRow: React.FC<{ label: string; children: React.ReactNode }> = ({
  label,
  children,
}) => (
  <>
    <div className="py-4 border-b border-gray-300 text-gray-500">{label}</div>
    <div className="py-4 border-b border-gray-300 text-sm">{children}</div>
  </>
);
