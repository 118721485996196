import { CustomerForm } from "../../components/forms/CustomerForm";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { createCustomer } from "../../services/CustomersService";
import useAuth from "../../hooks/useAuth";
import { OpenNotification } from "../../components/shared/Notification";
import { getCustomers, setModuleName } from "../../services/atoms";
import { useSetAtom } from "jotai";
import { Loading } from "../../components/shared/Loading";

export const CreateCustomer = () => {
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const refresh = useSetAtom(getCustomers);

  const setModule = useSetAtom(setModuleName);

  useEffect(() => {
    setModule("Clientes");
  }, [setModule]);

  const handleRequest = async (values: any) => {
    try {
      setLoading(true);
      const response = await createCustomer(auth.accessToken, values);
      OpenNotification("success", "bottomRight", response.message);
      refresh();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="h-screen bg-white p-5">
        <div className=" flex items-center">
          <Link to="/customers" className="text-black">
            <FaArrowLeft fontSize={20} />
          </Link>
          <div style={{ margin: 6 }} className="font-medium">
            Craer cliente
          </div>
        </div>
        <CustomerForm handleRequest={handleRequest} data={null} />
      </div>
    </div>
  );
};
