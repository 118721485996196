export const validatePassword = (_: any, value: any) => {
  if (!value) {
    return Promise.reject("Por favor, ingresa una contraseña.");
  }

  const passwordRegex = /^(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
  if (!passwordRegex.test(value)) {
    return Promise.reject(
      "La contraseña debe tener al menos 8 caracteres, una letra mayúscula y un carácter especial."
    );
  }

  return Promise.resolve();
};