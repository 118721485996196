import React from "react";
import { Button } from "antd";
import { deleteOperation } from "../../services/OperationsService";
import { notCustomer } from "../../validationSchemas/validateModules";
import RoleBased from "../shared/RoleBased";
import {
  getHistoryOperations,
  getOperations,
  tokenAtom,
} from "../../services/atoms";
import { useAtomValue, useSetAtom } from "jotai";
import { OpenNotification } from "../shared/Notification";
import { InboxOutlined } from "@ant-design/icons";
import { Operation } from "../../interfaces/models/Operation";

interface OperationCardListProps {
  operations: Operation[];
  handleCardClick:  (values: any) => void;
}

const OperationsList: React.FC<OperationCardListProps> = ({ operations,handleCardClick }) => {
  const token = useAtomValue(tokenAtom);
  const refreshOperations = useSetAtom(getOperations);
  const refreshHistory = useSetAtom(getHistoryOperations);
  
  const handleDelete = async (id: any, event: React.MouseEvent) => {
    try {
      event.stopPropagation();
      const opResponse = await deleteOperation(id, token);
      refreshOperations();
      refreshHistory();
      OpenNotification("success", "bottomRight", opResponse.message);
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <div className="space-y-3">
      {operations.map((data) => (
        <div
          key={data.id}
          className="bg-white rounded-xl shadow-sm p-4"
          onClick={() => handleCardClick(data.id)}
        >
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <span className="text-[#112752] font-medium" style={{maxWidth:210,overflow:"hidden",textOverflow:"ellipsis"}}>
                <InboxOutlined
                  style={{ fontSize: "17px", marginRight: "5px" }}
                />
                {data.name}
              </span>
              <span
                className={`text-xs px-2 py-0.5 rounded-full ${
                  data.status
                    ? "bg-green-100 text-green-700"
                    : "bg-orange-100 text-orange-700"
                }`}
              >
                {data.status ? "Finalizada" : "Pendiente"}
              </span>
            </div>
            <RoleBased allowedRoles={notCustomer}>
              <Button
                type="text"
                danger
                size="small"
                icon={
                  <span
                    className="text-lg"
                    onClick={(event) => handleDelete(data.id,event)}
                  >
                    x
                  </span>
                }
              />
            </RoleBased>
          </div>
          <p className="text-gray-600 text-sm mb-1">ID: {data.id}</p>
          <p className="text-gray-600 text-sm mb-1">
            Cliente: Empresa <b>{data.customerName}</b>
          </p>
          <p className="text-gray-500 text-sm">
            Descripción: {data.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default OperationsList;
