import React, { useState } from "react";
import { Layout, Input, Button, Form } from "antd";
import { UserCircle, Lock } from "lucide-react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { tokenAtom } from "../services/atoms";
import useAuth from "../hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import { getUser } from "../services/UsersService";
import { facebook, google, login } from "../services/AuthService";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { FaFacebook } from "react-icons/fa";
import { Loading } from "../components/shared/Loading";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [_, setToken] = useAtom(tokenAtom);

  const handleAuth = async (authFunction: any, token: any) => {
    try {
      const { data } = await authFunction(token);
      const decoded = jwtDecode(data) as { id: string; role: string };
      const userData = await getUser(decoded.id, data);
      setAuth({
        accessToken: data,
        id: decoded.id,
        role: decoded.role,
        getAuth: true,
        user: userData.data,
      });
      setToken(data);
      navigate("/home");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onLogin = async (values: any) => {
    setLoading(true);
    await handleAuth(() => login(values.username, values.password), null);
  };

  const onFacebookLogin = (response: any) => {
    setLoading(true);
    handleAuth(facebook, response.accessToken);
  };

  const onGoogleLogin = (response: any) => {
    setLoading(true);
    handleAuth(google, response.credential);
  };

  return loading ? (
    <Loading />
  ) : (
    <Layout className="min-h-screen bg-[#F5F7FA]">
      <div className="w-full max-w-sm mx-auto px-4 flex flex-col items-center justify-center min-h-screen">
        <div className="m-2 flex items-center gap-3">
          <img
            style={{ width: 221 }}
            src="/logo-evidence-app-large.png"
            alt="Logo"
          />
        </div>

        <div className="w-full bg-white rounded-xl p-8 shadow-sm border border-gray-100">
          <div className="text-center mb-8">
            <UserCircle className="w-12 h-12 mx-auto mb-3 text-secondary" />
            <h2 className="text-xl font-medium text-primary">Bienvenido</h2>
            <p className="text-sm text-gray-500 mt-1">
              Ingresa tus credenciales para continuar
            </p>
          </div>

          <Form
            form={form}
            onFinish={onLogin}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Por favor ingresa tu correo" },
              ]}
            >
              <Input
                prefix={<UserCircle className="text-secondary" size={18} />}
                placeholder="Correo electrónico"
                className="h-11 rounded-lg"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Por favor ingresa tu contraseña" },
              ]}
            >
              <Input.Password
                prefix={<Lock className="text-secondary" size={18} />}
                placeholder="Contraseña"
                className="h-11 rounded-lg"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full h-11 bg-accent hover:bg-warning text-primary font-medium text-base rounded-lg shadow-sm"
              >
                Ingresar
              </Button>
            </Form.Item>

            <div className="text-center">
              <a
                href="/new-pass-request"
                target="_blank"
                className="text-secondary hover:text-primary text-sm"
              >
                ¿Olvidaste tu contraseña?
              </a>
            </div>

            <div className="flex justify-center mt-5">
              <GoogleLogin
                type="icon"
                onSuccess={onGoogleLogin}
                onError={() => console.log("Login Failed")}
              />

              <FacebookLogin
                appId={process.env.REACT_APP_FB_APP_ID || ""}
                onSuccess={onFacebookLogin}
                onFail={(error) => console.log("Login Failed!", error)}
                style={{
                  backgroundColor: "#4267b2",
                  color: "#fff",
                  fontSize: "14px",
                  padding: "10px",
                  width: "44px",
                  height: "40px",
                  border: "none",
                  borderRadius: "4px",
                  marginLeft: "8px",
                }}
              >
                <FaFacebook style={{ fontSize: 23 }} />
              </FacebookLogin>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};
