import { CreateOperationForm } from "../../components/forms/CreateOperationForm";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useSetAtom } from "jotai";
import { setModuleName } from "../../services/atoms";
import { useEffect } from "react";
export const CreateOperation = () => {
  const setModule = useSetAtom(setModuleName);

  useEffect(()=>{
    setModule("Operaciones")
  },[setModule])
  
  return (
    <div>
      <div className="h-screen bg-white p-5">
        <div className="flex justify-between items-center">
          <Link to="/operations" className="text-black">
            <FaArrowLeft fontSize={20} />
          </Link>
        </div>
        <CreateOperationForm />
      </div>
    </div>
  );
};
