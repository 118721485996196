import { OpenNotification } from "../components/shared/Notification";

export const handleApiError = (error: any) => {
  if (error.response) {
    OpenNotification("error", "bottomRight", error.response.data?.message);
  } else {
    OpenNotification(
      "error",
      "bottomRight",
      "No se pudo conectar al servidor " + error.message
    );
  }
  throw error;
};
