import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, message, Spin } from "antd";
import { createOperation } from "../../services/OperationsService";
import { getAllUsers } from "../../services/UsersService";
import { getAllCustomers } from "../../services/CustomersService";
import useAuth from "../../hooks/useAuth";
import { Loading } from "../shared/Loading";
import { OpenNotification } from "../shared/Notification";
import { getOperations } from "../../services/atoms";
import { useSetAtom} from "jotai";
const { Option } = Select;

export const CreateOperationForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const refresh = useSetAtom(getOperations);
  const { auth } = useAuth();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const cus = await getAllCustomers(auth?.accessToken);
      const usrs = await getAllUsers(auth?.accessToken);
      setCustomers(cus.data);
      const filteredUsers = usrs.data.filter(
        (user: any) => user.role !== "CUSTOMER"
      );
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response = await createOperation(auth.accessToken, values);
      OpenNotification("success", "bottomRight", response.message);
      refresh();
      form.resetFields();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          name: "",
          description: "",
          userId: null,
          customerId: null,
        }}
        style={{marginTop:8}}
      >
        <Form.Item
          label="Nombre de la operación"
          name="name"
          rules={[{ required: true, message: "El nombre es obligatorio" }]}
        >
          <Input placeholder="Nombre de la operación" />
        </Form.Item>

        <Form.Item
          label="Descripción de la operación"
          name="description"
          rules={[{ required: true, message: "La descripción es obligatoria" }]}
        >
          <Input.TextArea placeholder="Descripción de la operación" rows={4} />
        </Form.Item>

        <Form.Item
          label="Cliente"
          name="customerId"
          rules={[{ required: true, message: "Debe seleccionar un cliente" }]}
        >
          <Select placeholder="Seleccione un cliente">
            {customers && customers.map(({ id, companyName }: any) => (
              <Option key={id} value={id}>
                {companyName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Usuario encargado"
          name="userId"
          rules={[
            {
              required: true,
              message: "Debe seleccionar un usuario encargado",
            },
          ]}
        >
          <Select placeholder="Seleccione un usuario encargado">
            {users && users.map(({ id, firstName, lastName }: any) => (
              <Option key={id} value={id}>
                {`${firstName} ${lastName}`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Crear Operación
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
